.activeProduct {
    background-color: #ff7f50;
    color: white;
    font-weight: 600;
}

.activeProduct > td {
    background-color: #ff7f50 !important;
}

/* Set the padding of the last column to zero as it is the copy button which shrinks otherwise */
.ant-table-tbody > tr > td:last-child {
    padding-right: 0px;
    padding-left: 0px;
    text-align: center;
}
