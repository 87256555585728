details summary::after {
    /* margin-right: var(--s-1);
    background-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -37px; */
    display: none;
}

details:hover {
    background-color: var(--hover);
}

summary {
    padding: var(--s-2) 0;
}

.ant-drawer-content-wrapper {
    padding-top: 44px;
}

.ant-collapse-header {
    border: none;
}

.ant-collapse-arrow {
    position: absolute;
    right: 0;
    top: 19px;
}

.ant-collapse-content-box {
    padding: 25px 15px;
}
