.ant-checkbox-checked .ant-checkbox-inner {
    width: 19px;
    height: 19px;
}

.ant-checkbox-inner {
    width: 19px !important;
    height: 19px !important;
}

.ant-checkbox + span {
    font-size: 17px;
    font-weight: 400;
    margin-right: 7px;
}

.ant-checkbox-inner::after {
    left: 25% !important;
}

.ant-radio-inner {
    width: 19px !important;
    height: 19px !important;
}

.ant-radio + span {
    font-size: 17px;
    font-weight: 300;
    margin-right: 7px;
}

.menu-item-container:hover {
    background-color: var(--grey-lighter) !important;
}
