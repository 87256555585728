select {
    width: 100%;
    padding: 4px 11px;
    border-radius: 4px;
    background-color: transparent;
    line-height: 30px;
}

option {
    color: black;
}

/* See src/styles/colours.css for more info on the below pattern */

[data-theme='light'] select,
body:not([data-theme='dark']) select {
    border: 1px solid #d9d9d9;
}

@media only screen and (prefers-color-scheme: dark) {
    body:not([data-theme='light']) select {
        border: 1px solid #6c6d6f;
    }
}

[data-theme='dark'] select {
    border: 1px solid #6c6d6f;
}
