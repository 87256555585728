.ant-upload.ant-upload-select-picture-card {
    margin: 0 !important;
    width: 100% !important;
    height: inherit !important;
}

/* The "+ Upload" text */
.ant-upload.ant-upload-select-picture-card > span > div {
    padding: var(--s0) 0 !important;
}

.image-form {
    border-color: var(--grey-light) !important;
}

.image-form .ant-collapse-content-box {
    padding: var(--s-1) !important;
}
