.sidebar-list-item {
    border-bottom: 1px solid var(--grey-light);
    padding-bottom: 20px !important;
    padding-top: 20px !important;
    margin-top: -10px;
}

.sidebar-list-item:hover {
    background-color: var(--grey-lighter);
}

.ant-drawer-close {
    padding-left: 0;
}

.ant-drawer-body {
    padding: var(--s-1) var(--s1) var(--s0) !important;
    overscroll-behavior: contain;
}
