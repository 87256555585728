/* These define a few high-level CSS variables for light/dark colour schemes largely outside of antdesign. */

/* Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme='light'],
:root:not([data-theme='dark']) {
    --white: #fff;
    --background-colour: #fff;
    --contrast-colour: #000;
    --grey: hsl(0, 0%, 67%);
    --grey-light: hsl(0, 0%, 85%);
    --grey-lighter: hsl(0, 0%, 94%);
    --grey-dark: hsl(0, 0%, 40%);
    --blue-lighter: hsl(214, 75%, 90%);
    --orange-lt: #f5a447;
    --orange: #fa8500;
}

/* Dark scheme (Auto) */
/* Automatically enabled if user has Dark mode enabled */
@media only screen and (prefers-color-scheme: dark) {
    :root:not([data-theme='light']) {
        /* VERY IMPORTANT: WET with below code */
        --white: #fff;
        --background-colour: #1c2228;
        --contrast-colour: #fff;
        --grey: #96a7b5;
        --grey-light: #3e474d;
        --grey-lighter: #2c3337;
        --grey-dark: #b0c2d1;
        --blue-lighter: hsl(214, 65%, 41%);
        --orange-lt: #f5a447;
        --orange: #fa8500;
    }
}

/* Dark scheme (Forced) */
/* Enabled if forced with data-theme="dark" */
[data-theme='dark'] {
    /* VERY IMPORTANT: WET with above code */
    --white: #fff;
    --background-colour: #1c2228;
    --contrast-colour: #fff;
    --grey: #96a7b5;
    --grey-light: #3e474d;
    --grey-lighter: #2c3337;
    --grey-dark: #b0c2d1;
    --blue-lighter: hsl(214, 65%, 41%);
    --orange-lt: #f5a447;
    --orange: #fa8500;
}
