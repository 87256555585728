.dragger {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    cursor: col-resize;
    z-index: 10000;
}

.dragger:hover {
    background-color: var(--grey-lighter);
}
