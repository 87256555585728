nav li {
    font-size: 1.2rem;
}

[aria-current] {
    position: relative;
    display: inline-block;
}

[aria-current]::after {
    position: absolute;
    content: '';
    width: calc(100% - 1em);
    height: 3px;
    background-color: var(--primary);
    display: block;
    top: 0;
}

.ant-menu-horizontal {
    border-bottom: none !important;
}
