.weather-select {
    height: 100px;
}

.weaklayer-select .ant-select-selector {
    height: 93px !important;
}

.ant-select-tree-switcher {
    position: relative;
    flex: 0 0 auto;
    align-self: stretch;
    width: 0px !important;
    margin: 0px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.ant-select-tree .ant-select-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0px 0px 0px !important;
    outline: 0px;
}

.ant-tree-select-dropdown {
    padding: 8px 0px !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
    padding: 0px !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: var(--background-colour) !important;
}

.weaklayer-tree-node:hover {
    border-left: 3px solid var(--blue) !important;
    background: var(--grey-lighter);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    /* display: none !important; */
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
}
