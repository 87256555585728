html,
body {
    position: relative;
    width: 100%;
    height: 100%;
}
body {
    background-color: var(--background-colour);
}

/*
Setting up spaces
See https://absolutely.every-layout.dev/rudiments/modular-scale/
*/
:root {
    /* Globals */
    --radius: 4px;
    --ratio: 1.5;
    --s-5: calc(var(--s-4) / var(--ratio));
    --s-4: calc(var(--s-3) / var(--ratio));
    --s-3: calc(var(--s-2) / var(--ratio));
    --s-2: calc(var(--s-1) / var(--ratio));
    --s-1: calc(var(--s0) / var(--ratio));
    --s0: 1rem;
    --s1: calc(var(--s0) * var(--ratio));
    --s2: calc(var(--s1) * var(--ratio));
    --s3: calc(var(--s2) * var(--ratio));
    --s4: calc(var(--s3) * var(--ratio));
    --s5: calc(var(--s4) * var(--ratio));

    --blue-color-wheel-degrees: 214;
    --blue: hsl(var(--blue-color-wheel-degrees), 65%, 41%);
    --blue-lt: hsl(var(--blue-color-wheel-degrees), 75%, 60%);
    --blue-lighter: hsl(var(--blue-color-wheel-degrees), 75%, 90%);
    --hover: hsl(0, 0%, 93%);
    --red-color-wheel-degrees: 0;
    --red: hsl(var(--red-color-wheel-degrees), 100%, 45%);
    --red-lt: hsl(var(--red-color-wheel-degrees), 70%, 90%);
    --yellow-color-wheel-degrees: 60;
    --yellow: hsl(var(--yellow-color-wheel-degrees), 100%, 50%);
    --green-color-wheel-degrees: 142;
    --green: hsl(var(--green-color-wheel-degrees), 50%, 40%);
}

#root,
.ant-layout,
.App {
    height: 100%;
}

.page-content {
    padding: var(--s1);
}

.page-content article {
    max-width: 1024px;
    padding: var(--s1);
    margin: 0 auto;
    background: var(--background-colour);
    border-radius: var(--radius);
    box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.06), 0 0.125rem 2rem rgba(0, 0, 0, 0.12),
        0 0 0 0.0625rem rgba(0, 0, 0, 0.036);
}

.capitalize {
    text-transform: capitalize;
}

button {
    width: fit-content;
    padding: 3px 19px;
    height: fit-content;
    border-radius: 3px;
    margin-top: 8px;
    font-weight: 500;
}

.mapboxgl-ctrl button.active {
    background-color: rgba(0, 0, 0, 0.1);
}

.mapboxgl-ctrl button.inactive {
    display: none;
}

.ant-card-head {
    background-color: var(--grey-lighter) !important;
    padding: 0 var(--s-1) !important;
}

.ant-card {
    border-color: var(--grey-light) !important;
}

.ant-card-body {
    padding: var(--s-1) !important;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid var(--grey-light) !important;
}

.ant-collapse-content {
    border-top: 1px solid var(--grey-light) !important;
}
